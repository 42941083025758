import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css";
import "./styles/empowered_projectstyle.css";

// Importing images
import empoweredImage1 from "./assets/empowered_laptop.png";
import empoweredImage2 from "./assets/empowered_phone.png";
import empoweredImage3 from "./assets/empowered_old.png";

export const EmpoweredProject = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Initialize AOS animations
    AOS.init({ duration: 1000, offset: 100 });
  }, []);

  return (
    <div ref={scrollRef} className="empowered-container">
      {/* Heading */}
      <h2 className="empowered-title" data-aos="fade-up">
        Case Study: Redesigning EmpowerEd's Website for a Modern Era
      </h2>

      {/* Image Slider */}
      <div className="empowered-slider" data-aos="zoom-in">
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          slidesPerView={1}
        >
          {[empoweredImage1, empoweredImage2, empoweredImage3].map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`Empowered Design Slide ${index + 1}`}
                className="responsive-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Content Sections */}
      <div className="empowered-details">
        <div className="empowered-overview" data-aos="fade-right">
          <h4 className="section-heading">Background</h4>
          <p>
            As a tutor at EmpowerEd Education, I have always been passionate about helping
            students unlock their potential. However, I realized that our online presence, 
            specifically our website, did not reflect the same energy, innovation, and 
            commitment to growth that we emphasize in our teaching.
          </p>

          {/* Identifying the Problem */}
          <h4 className="section-heading">Identifying the Problem</h4>
          <p className="section-gap">The current website had several limitations:</p>
          <ul className="bullet-list">
            <li>Outdated design lacking visual appeal and modern trends.</li>
            <li>Poor navigation, making information hard to find.</li>
            <li>No interactive elements, animations, or compelling visuals.</li>
            <li>Limited calls-to-action for user engagement.</li>
          </ul>
          <p className="section-gap">
            These issues impacted user experience and failed to highlight EmpowerEd’s 
            award-winning services effectively.
          </p>

          {/* Proposal */}
          <h4 className="section-heading">The Proposal</h4>
          <p className="section-gap">
            With a clear understanding of these shortcomings, I proposed a redesign that:
          </p>
          <ul className="bullet-list">
            <li>Features a clean layout with visual storytelling.</li>
            <li>Incorporates interactive elements and animations.</li>
            <li>Provides clear navigation to simplify the user journey.</li>
            <li>Highlights services, success stories, and unique offerings.</li>
            <li>Includes prominent calls-to-action to boost inquiries and bookings.</li>
          </ul>
        </div>

        {/* Buttons */}
        <div className="empowered-buttons" data-aos="fade-left">
          <a
            href="https://www.figma.com/design/KEtx0Ju1ZAfCrN0hvsy8jD/EducationEmpowered?node-id=249-3&t=cRO14yivEcKpUUiK-1"
            className="empowered-button button-outline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Figma Prototype
          </a>
          <a
            href="https://www.empowered-education.co.uk/"
            className="empowered-button button-solid"
            target="_blank"
            rel="noopener noreferrer"
          >
            Old Website Design
          </a>
        </div>

        {/* Design Principles */}
        <div className="empowered-principles" data-aos="fade-up">
          <h3>Design Principles Used</h3>
          <ul className="bullet-list">
            <li>
              <strong>Color Palette:</strong> Bright, engaging colors to reflect creativity.
            </li>
            <li>
              <strong>Typography:</strong> Clear, legible fonts for readability across devices.
            </li>
            <li>
              <strong>Imagery:</strong> Vibrant visuals resonating with learners and educators.
            </li>
            <li>
              <strong>White Space:</strong> Clean layouts for readability and focus.
            </li>
            <li>
              <strong>Responsive Design:</strong> Seamless experience on mobile and desktop.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmpoweredProject;
