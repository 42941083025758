import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css"; // Swiper styles
import "./styles/carehome.css"; // Your CSS
import careHomeImage4 from "./assets/carehome_mockup.png";
import careHomeImage3 from "./assets/carehome-phone.png";

export const CareHomeProject = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({ duration: 1000, offset: 100 });
  }, []);

  return (
    <div ref={scrollRef} className="care-container">
      {/* Heading */}
      <h2 className="care-title" data-aos="fade-up">
        Case Study: Modern UI/UX Design for a Care Home Landing Page
      </h2>

      {/* Slider */}
      <div className="care-image" data-aos="zoom-in">
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          slidesPerView={1}
          className="care-slider"
        >
          <SwiperSlide>
            <img
              src={careHomeImage4}
              alt="Care Home Design Slide 4"
              className="responsive-image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={careHomeImage3}
              alt="Care Home Design Slide 3"
              className="responsive-image"
            />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Content Sections */}
      <div className="care-details">
        <div className="care-overview" data-aos="fade-right">
          <h3>Client Overview</h3>
          <p>
            St. Agnes Care is a care home facility providing exceptional medical attention,
            top-quality care, and a home-like environment for seniors. The client’s vision
            was to design a professional, clean, and modern landing page that promotes trust,
            reliability, and a compassionate tone for their services.
          </p>
        </div>

        <div className="care-buttons" data-aos="fade-left">
          <a
            href="https://www.figma.com/prototype/project-link"
            className="care-button button-outline"
          >
            Figma Prototype
          </a>
          <a
            href="https://github.com/your-repo/project-link"
            className="care-button button-solid"
          >
            Github
          </a>
        </div>

        <div className="care-principles" data-aos="fade-up">
          <h3>Design Principles Used</h3>
          <ul>
            <li>
              <strong>Color Palette:</strong> A soft pink tone dominates the design to evoke
              comfort, care, and warmth.
            </li>
            <li>
              <strong>Typography:</strong> Bold headings for emphasis and clean sans-serif
              fonts create a modern, professional aesthetic.
            </li>
            <li>
              <strong>Imagery:</strong> Human-centric visuals of elderly individuals and
              caregivers convey empathy and trust.
            </li>
            <li>
              <strong>White Space:</strong> Generous spacing improves readability.
            </li>
            <li>
              <strong>Responsive Design:</strong> Components are scalable for mobile and desktop users.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CareHomeProject;
