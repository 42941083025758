import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/portfolio_style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

// Import project images
import project1 from "./assets/empowered.png";
import project2 from "./assets/carehome_mockup.png";
import project3 from "./assets/doc2.png";
import project4 from "./assets/onboarding.png";
import project5 from "./assets/tmovies.png";
import project6 from "./assets/weather.png";
import project7 from "./assets/quantum.png";
import project8 from "./assets/fleet.png";
import project9 from "./assets/bukka.png";
import project from "./assets/Diivix.png";

// Tab Button Component
const TabButton = ({ tab, isActive, onClick }) => (
  <button
    className={`tab-button ${isActive ? "active" : ""}`}
    onClick={onClick}
    aria-label={`Show projects for ${tab}`}
  >
    {tab.charAt(0).toUpperCase() + tab.slice(1)} {tab === "figma" ? "UI/UX" : ""}
  </button>
);

// Project Card Component
const ProjectCard = ({ project, onNavigate }) => (
  <div
    className="project-card"
    data-aos="zoom-in"
    onClick={() => onNavigate(project.link)}
    onKeyPress={(e) => e.key === "Enter" && onNavigate(project.link)}
    role="button"
    tabIndex="0"
    aria-label={`View project: ${project.title}`}
  >
    <img className="project-image" src={project.image} alt={project.title} />
    <div className="hline"></div>
    <div className="project-info">
      <p>{project.title}</p>
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        className="project-link-icon"
      />
    </div>
  </div>
);

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("figma");
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000, offset: 100 });
    return () => AOS.refresh(); // Refresh AOS on component re-render
  }, []);

  // Projects
  const projects = {
    figma: [
      { title: "Upgrading EmpowerEd Website", image: project1, link: "/empowered-project" },
      { title: "CareHome Website", image: project2, link: "/carehome" },
      { title: "Doctor Appointment App", image: project3, link: "/docui" },
      { title: "Food Ordering App UI", image: project4, link: "/bukkaui" },
    ],
    web: [
      { title: "Food Ordering Web App", image: project9, link: "/bukkawebapp" },
      { title: "Movie React App", image: project5, link: "/tmovies" },
      { title: "Weather React App", image: project6, link: "/weather" },
    ],
    CMS: [
      { title: "Quantum Tech Website", image: project7, link: "/quantumn" },
      { title: "Fleet Management Website", image: project8, link: "/fleet" },
    ],
    Andriod: [
      { title: "Diivix Sprituality Mobile App", image: project, link: "/diivix" },
    ],
  };

  const tabs = ["figma", "web", "CMS", "Andriod"];

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Update tab immediately
  };

  return (
    <div className="portfolio" id="portfolio" data-aos="fade-up">
      <div className="portfolio-header">
        <h2 className="portfolio-title">
          MY PORTFOLIO
          <span className="portfolio-line"></span>
        </h2>
      </div>

      {/* Tab Navigation */}
      <div className="tabs">
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            tab={tab}
            isActive={activeTab === tab}
            onClick={() => handleTabChange(tab)}
          />
        ))}
      </div>

      {/* Projects Grid */}
      <div className="projects fade-in">
        {projects[activeTab]?.length > 0 ? (
          projects[activeTab].map((project, index) => (
            <ProjectCard
              key={index}
              project={project}
              onNavigate={navigate}
            />
          ))
        ) : (
          <p className="no-projects" data-aos="fade-up">
            No projects available in this category.
          </p>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
