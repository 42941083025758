import React from "react";
import "./styles/footer_style.css";
import linkedin from "./assets/linkedin.png";
import instagram from "./assets/Instagram.png";
import github from "./assets/Github.png";
import whatsapp from "./assets/WhatsApp.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 className="footer-title">Social Media</h3>
          <div className="social-icons">
            <a
              href="https://github.com/franschezco"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <img
                className="social-image"
                alt="GitHub"
                src={github}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/franschezco/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <img
                className="social-image"
                alt="LinkedIn"
                src={linkedin}
              />
            </a>
            <a
              href="https://instagram.com/leox_frankoe"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <img
                className="social-image"
                alt="Instagram"
                src={instagram}
              />
            </a>
            <a
              href="https://wa.me/447576531253"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <img
                className="social-image"
                alt="WhatsApp"
                src={whatsapp}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Francis Okoye. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; // Default export
