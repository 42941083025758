import React, { useState, useEffect, useRef } from "react";
import "./styles/hero_style.css";
import ModelViewer from "./ModelViewer"; // Import the 3D ModelViewer component

const Hero = () => {
  // State for the animated words
  const [currentWord, setCurrentWord] = useState("Create");
  const words = ["Create", "Code"]; // Words to alternate between

  // Reference for the resume button position
  const viewResumeRef = useRef(null);
  const [arrowPosition, setArrowPosition] = useState({ top: 0, left: 0 });

  // Word Animation Effect - Switch between words every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prevWord) =>
        prevWord === words[0] ? words[1] : words[0]
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [words]); // Dependency added for ESLint compliance

  // Calculate Arrow Position dynamically for visual alignment
  useEffect(() => {
    const calculateArrowPosition = () => {
      if (viewResumeRef.current) {
        const rect = viewResumeRef.current.getBoundingClientRect();
        setArrowPosition({
          top: rect.top + rect.height / 2 - 10,
          left: rect.left - 30,
        });
      }
    };

    // Initial calculation and listen for window resize
    calculateArrowPosition();
    window.addEventListener("resize", calculateArrowPosition);

    // Cleanup listener on unmount
    return () =>
      window.removeEventListener("resize", calculateArrowPosition);
  }, []);

  // Smooth scroll to Portfolio Section
  const scrollToPortfolio = () => {
    const portfolioSection = document.getElementById("portfolio");
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Open Resume Link in a New Tab
  const openResume = () => {
    window.open(
      "https://drive.google.com/file/d/1313G3ng7x0_80gmTb9-Tg60e6Fyb4ls8/view?usp=sharing",
      "_blank"
    ); // Opens the C.V link in a new tab
  };

  return (
    <div className="hero">
      {/* Background overlay */}
      <div className="spider-web-overlay"></div>

      {/* Hero Container */}
      <div className="hero-container">
        {/* Hero Left Section */}
        <div className="hero-left">
          <div className="intro">
            <p className="greeting">
              Hi, <span className="wave">👋</span> I’m{" "}
              <span className="name">Francis Okoye</span>, a Web Designer /
              Developer
            </p>
          </div>

          {/* Tagline with Animated Words */}
          <div className="tagline">
            <span className="word-think">Think, </span>
            <span className="word-visualise">Visualise, </span>
            <span className="word-create">{currentWord}</span>
          </div>

          {/* Subtext */}
          <p className="subtext">My Approach to Exceptional Web Design.</p>

          {/* Buttons */}
          <div className="buttons">
            <button
              className="button recent-projects"
              onClick={scrollToPortfolio} // Scroll to portfolio section
            >
              Recent Projects
            </button>
            <button
              className="button view-resume"
              ref={viewResumeRef}
              onClick={openResume} // Open resume link
            >
              View C.V
            </button>
          </div>
        </div>

        {/* Hero Right Section with 3D Model */}
        <div className="hero-right">
          <ModelViewer />
        </div>

        {/* Arrow Pointer */}
        <div
          className="cursor-arrow"
          style={{
            position: "absolute",
            top: `${arrowPosition.top}px`,
            left: `${arrowPosition.left}px`,
          }}
        />
      </div>
    </div>
  );
};

export default Hero;
