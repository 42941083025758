// firebase.js

// Import necessary Firebase functions
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCaMyj-geVKHCo_TlPnDC0ulawcT4ev5QY",
  authDomain: "html-portfolio-1c665.firebaseapp.com",
  projectId: "html-portfolio-1c665",
  storageBucket: "html-portfolio-1c665.firebasestorage.app",
  messagingSenderId: "250883027549",
  appId: "1:250883027549:web:a146f0b2d631b0f7d853b4",
  measurementId: "G-HCP0PTVT6H",
};

// Initialize Firebase and Analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
