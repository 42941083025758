import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF, Html } from "@react-three/drei";
import * as THREE from "three";

// Model Component
const Model = ({ scale }) => {
  const modelRef = useRef();
  const { scene, animations } = useGLTF("/models/my-3d-model.glb"); // Replace with your model path
  const mixer = useRef();

  // Play animations when loaded
  useEffect(() => {
    if (animations && animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(scene);
      animations.forEach((clip) => {
        const action = mixer.current.clipAction(clip);
        action.play(); // Play animation
      });
    }
  }, [animations, scene]);

  // Update animation frames
  useFrame((state, delta) => {
    if (mixer.current) mixer.current.update(delta);
  });

  return (
    <primitive
      ref={modelRef}
      object={scene}
      scale={scale} // Scale based on responsive settings
      position={[0, -0.5, 0]} // Position adjustment
      rotation={[0, Math.PI / 8, 0]} // Rotation adjustment
    />
  );
};

// Model Viewer Component
const ModelViewer = () => {
  const getResponsiveSettings = () => {
    const width = window.innerWidth;

    if (width <= 480) {
      return { scale: [1.1, 1.1, 1.1], position: [0, 0, 3], fov: 60 };
    } else if (width <= 768) {
      return { scale: [1.0, 1.0, 1.0], position: [0, 0, 3], fov: 50 };
    } else {
      return { scale: [0.8, 0.8, 0.8], position: [0, 1, 3.5], fov: 40 };
    }
  };

  const { scale, position, fov } = getResponsiveSettings();

  return (
    <Canvas
      camera={{ position, fov }}
      gl={{ alpha: true }}
      onPointerDown={(e) => e.stopPropagation()} // Disable gestures on tap/click
      onTouchMove={(e) => e.stopPropagation()}   // Prevent drag-to-scroll behavior
    >
      {/* Lights */}
      <ambientLight intensity={0.8} />
      <directionalLight position={[3, 10, 5]} intensity={1} castShadow />
      <spotLight position={[0, 5, 10]} angle={0.3} penumbra={1} intensity={1.2} />
      <pointLight position={[-5, 5, 5]} intensity={0.7} />

      {/* Suspense with Html fallback */}
      <Suspense
        fallback={
          <Html center>
            <div style={{ color: "white", fontSize: "1.2rem" }}>Loading...</div>
          </Html>
        }
      >
        <Model scale={scale} />
      </Suspense>

      {/* OrbitControls with Disabled Gestures */}
      <OrbitControls
        enableZoom={false}      // Disable zoom
        enableRotate={false}    // Disable rotation
        enablePan={false}       // Disable panning
        enableDamping={true}    // Smooth interactions (if enabled later)
      />
    </Canvas>
  );
};

export default ModelViewer;
