import React from "react";
import ReusableComponent from "./ReusableComponent";

// Fleet Management Page
export const FleetManagement = () => {
  const data = {
    title: "Fleet Management Website",
    sliderImages: [
      require("./assets/fleet.png"),
      require("./assets/fleet2.png"),
    ],
    overview:
      "This website was developed to showcase Petrosoft’s Fleet Management Services, providing tools and consultancy for: Driver Acquisition, Verification, Asset Recovery. The website effectively combines modern UI elements with clear calls-to-action, aimed at improving user engagement and lead generation.This WordPress site effectively communicates Petrosoft’s fleet management services while offering an intuitive and modern user experience.",
    designPrinciples: [
        {
            title: "Platform",
            description: "WordPress.",
          },
          {
            title: "Page Builder",
            description: "Elementor Plugin for custom design and layouts.",
          },
          {
            title: "SEO Tool",
            description: "Yoast SEO for keyword optimization and meta descriptions.",
          },
          {
            title: "Form Management",
            description: "Contact Form plugin for inquiry submissions.",
          },
    ],
    links: [
      {
        url: "https://www.thefleetmanagement.com/",
        label: "Live Website",
        type: "button-outline",
      },
     
    ],
  };
  return <ReusableComponent {...data} />;
};

// Quantum Tech Website
export const QuantumTechWebsite = () => {
  const data = {
    title: "Quantum Tech Website",
    sliderImages: [
      require("./assets/quantum.png"),
      require("./assets/quantum2.png"),
    ],
    overview:
      "This project involved creating a professional and modern website for Quantum Tech using WordPress. The goal was to showcase their innovative IT solutions and products, focusing on user experience, responsiveness, and search engine optimization.The Quantum Tech website is a visually appealing and functional platform that effectively highlights the company’s services. Its user-friendly interface, optimized performance, and mobile compatibility make it a strong marketing tool for lead generation and customer engagement.",
    designPrinciples: [
      {
        title: "Platform",
        description: "WordPress.",
      },
      {
        title: "Page Builder",
        description: "Elementor Plugin for custom design and layouts.",
      },
      {
        title: "SEO Tool",
        description: "Yoast SEO for keyword optimization and meta descriptions.",
      },
      {
        title: "Form Management",
        description: "Contact Form plugin for inquiry submissions.",
      },
    ],
    links: [
      {
        url: "https://quantumztech.com/",
        label: "Live Website",
        type: "button-outline",
      },
      
    ],
  };
  return <ReusableComponent {...data} />;
};

// Weather React App
export const WeatherReactApp = () => {
  const data = {
    title: "Weather React App",
    sliderImages: [
      require("./assets/weather.png"),
      require("./assets/weather1.png"),
    ],
    overview:
      "A weather forecasting app providing real-time updates and forecasts.Provides real-time weather updates for any city using data from weather.org API.Input field allows users to search for weather information based on city names.Current temperature with condition description.Wind speed, humidity, pressure, and temperature range.Toggle between English and French for multilingual accessibility.",
    designPrinciples: [
        {
            title: "Frontend Framework",
            description: "React.js.",
          },
          {
            title: "Styling Framework",
            description: "Tailwind CSS.",
          },
          {
            title: "API Integration",
            description: "WEATHER.ORG API for fetching weather data.",
          },
          {
            title: "UI Components",
            description: "Custom-built React components for modularity",
          },
    ],
    links: [
      {
        url: "https://github.com/franschezco/weather_app",
        label: "Github Repo",
        type: "button-outline",
      },
      {
        url: "https://weather-8vwwufgup-frankoes-projects.vercel.app/",
        label: "Live Demo",
        type: "button-solid",
      },
    ],
  };
  return <ReusableComponent {...data} />;
};

// Movie React App
export const MovieReactApp = () => {
  const data = {
    title: "TMovies: Movie React App",
    sliderImages: [
      require("./assets/tmovies.png"),
      require("./assets/tmovies2.png"),
    ],
    overview:
      "This project is a React Movie Rating Website built using React.js and Tailwind CSS. It leverages the TMDB API to fetch and display movie details, ratings, and star characters. The website provides an engaging and dynamic user interface for browsing movies, watching trailers, and exploring information about popular films.",
    designPrinciples: [
      {
        title: "Frontend Framework",
        description: "React.js.",
      },
      {
        title: "Styling Framework",
        description: "Tailwind CSS.",
      },
      {
        title: "API Integration",
        description: "TMDB API for fetching movie data.",
      },
      {
        title: "UI Components",
        description: "Custom-built React components for modularity",
      },
    ],
    links: [
      {
        url: "https://github.com/franschezco/tmovies",
        label: "Github Repo",
        type: "button-outline",
      },
      {
        url: "https://tmovies-blush.vercel.app",
        label: "Live Demo",
        type: "button-solid",
      },
    ],
  };
  return <ReusableComponent {...data} />;
};

// Food Ordering App UI
export const FoodOrderingPage = () => {
  const data = {
    title: "Bukkka: Food Ordering App UI",
    sliderImages: [
      require("./assets/bukka3.png"),
      require("./assets/onboarding.png"),
      require("./assets/bukka2.png"),
    ],
    overview:
      "The Bukka Food Delivery App is designed to cater to users seeking African dishes, with a focus on intuitive browsing, ordering, and delivery services. The interface emphasizes simplicity and accessibility, ensuring users can navigate seamlessly through the application.The Bukka Food Delivery App focuses on user-friendly navigation, vibrant imagery of dishes, and secure login methods to enhance customer experience. The warm color scheme and straightforward layout complement its cultural focus, appealing to food lovers seeking African cuisine.",
    designPrinciples: [
      {
        title: "Color Palette",
        description: "Primary Colors:Gold (#D4A055) - Used for buttons and highlights.Beige (#FDF6E4) - Background for a soft and warm aesthetic.Accent Colors:Black (#2D2D2D) - Text color for strong readability.White (#FFFFFF) - Clean contrast for form fields and highlights.",
      },
      {
        title: "Typography",
        description: "Fonts are clean and modern, likely using sans-serif styles for readability. Examples: Poppins, Lato, or Roboto..",
      },
      {
        title: "Navigation",
        description: "Easy navigation for quick ordering.",
      },
    ],
    links: [
      {
        url: "https://www.figma.com/design/4wxy7eizOjy69ioZIbfDeN/bukka?node-id=260-478&t=CqUpgxTi6cyw2yuF-1",
        label: "Figma Prototype",
        type: "button-outline",
      },
      {
        url: "https://github.com/frankoe-dev/bukka_app",
        label: "Github",
        type: "button-solid",
      },
    ],
  };
  return <ReusableComponent {...data} />;
};



// Bukka Ordering App UI
export const BukkaWebApp = () => {
  const data = {
    title: "Bukkka Web: Web Ordering App UI",
    sliderImages: [
      require("./assets/bukka.png"),
      require("./assets/bukka-phone.png")
    ],
    overview:
      "Bukka is a responsive web app built with Laravel, JavaScript, PHP 8.2, and MySQL for seamless food ordering. It supports PayPal and cash-on-delivery payments.",
    designPrinciples: [
      {
        title: "User Login Details",
        description: "Admin Email : admin-bukka@gmail.com - Admin Password : 123456789 | User Email : uche@gmail.com - User Password : 123456789",
      },
      {
        title: "Stack Used",
        description: "Laravel, PHP , Javascipt , MySQL.",
      },
      {
        title: "Packages",
        description: "PayPal, Auth Package , .",
      },
    ],
    links: [
      {
        url: "https://www.bukka-webapp.site",
        label: "View Demo",
        type: "button-outline",
      },
      {
        url: "https://github.com/franschezco/bukka",
        label: "Github Link",
        type: "button-solid",
      },
    ],
  };
  return <ReusableComponent {...data} />;
};



export const DiivixApp = () => {
  const data = {
    title: "Diivix: A Chritian Mobile Sprituality App",
    sliderImages: [
      require("./assets/Diivix.png"),
    ],
    overview:
      "Diivix is a mobile and web application currently in production, built with Flutter. It is designed to support spirituality and churches, offering tools for community engagement and management.",
    designPrinciples: [
      {
        title: "Language Used",
        description: "Flutter",
      }
    ],
    links: [
      {
        url: "https://play.google.com/store/apps/details?id=com.diivix.android",
        label: "Playstore Link",
        type: "button-outline",
      },
   
    ],
  };
  return <ReusableComponent {...data} />;
};
