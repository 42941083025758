import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "./index.css";
import App from "./App";
import "./App.css"; // Include global and preloader styles

// App Loader Wrapper Component
const AppWithPreloader = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading the 3D model and assets
  useEffect(() => {
    const preloadAssets = async () => {
      // Simulate 3D Model loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Hide preloader after assets load
      const preloader = document.getElementById("preloader");
      if (preloader) {
        preloader.classList.add("hidden"); // Fade out
        setTimeout(() => preloader.remove(), 500); // Remove from DOM
      }
      setIsLoading(false); // Update loading state
    };

    preloadAssets();
  }, []);

  return isLoading ? null : <App />;
};

// React Rendering
const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppWithPreloader />
      </BrowserRouter>
    </React.StrictMode> 
  );
} else {
  console.error("Root element not found. Make sure the element with id 'root' exists in your HTML.");
}
