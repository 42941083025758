import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css"; // Swiper styles
import "./styles/carehome.css"; // Shared CSS for all pages

const ReusableComponent = ({
  title,
  sliderImages,
  overview,
  designPrinciples,
  links,
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Initialize AOS for animations
    AOS.init({ duration: 1000, offset: 100 });
  }, []);

  return (
    <div ref={scrollRef} className="care-container">
      {/* Heading */}
      <h2 className="care-title" data-aos="fade-up">
        {title}
      </h2>

      {/* Slider */}
      <div className="care-image" data-aos="zoom-in">
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          slidesPerView={1}
          className="care-slider"
        >
          {sliderImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="responsive-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Content Sections */}
      <div className="care-details">
        <div className="care-overview" data-aos="fade-right">
          <h3>Client Overview</h3>
          <p>{overview}</p>
        </div>

        <div className="care-buttons" data-aos="fade-left">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className={`care-button ${link.type}`}
            >
              {link.label}
            </a>
          ))}
        </div>

        <div className="care-principles" data-aos="fade-up">
          <h3>Design Principles Used</h3>
          <ul>
            {designPrinciples.map((principle, index) => (
              <li key={index}>
                <strong>{principle.title}:</strong> {principle.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReusableComponent;
