import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import ScrollToTop from "./ScrollToTop";
import HeaderHome from "./pages/HeaderHome";
import HeaderBack from "./pages/HeaderBack";
import Footer from "./pages/Footer";
import Hero from "./pages/Hero";
import Portfolio from "./pages/Portfolio";
import Carehome from "./pages/CareHomeProject";
import EmpoweredProject from "./pages/EmpoweredProject";
import DoctorAppointmentPage from "./pages/DoctorAppointmentPage";
import {
  FoodOrderingPage,
  MovieReactApp,
  WeatherReactApp,
  QuantumTechWebsite,
  FleetManagement,
  BukkaWebApp,
  DiivixApp,
} from "./pages/projectpages";

const Home = () => (
  <>
    <Hero />
    <Portfolio />
  </>
);

const FirebaseAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", { page_path: location.pathname });
    }
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", { page_path: window.location.pathname });
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <FirebaseAnalytics />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeaderHome />
              <Home />
            </>
          }
        />
        <Route
          path="/empowered-project"
          element={
            <>
              <HeaderBack />
              <EmpoweredProject />
            </>
          }
        />
        <Route
          path="/carehome"
          element={
            <>
              <HeaderBack />
              <Carehome />
            </>
          }
        />
        <Route
          path="/docui"
          element={
            <>
              <HeaderBack />
              <DoctorAppointmentPage />
            </>
          }
        />
        <Route
          path="/bukkaui"
          element={
            <>
              <HeaderBack />
              <FoodOrderingPage />
            </>
          }
        />
        <Route
          path="/bukkawebapp"
          element={
            <>
              <HeaderBack />
              <BukkaWebApp />
            </>
          }
        />
        <Route
          path="/diivix"
          element={
            <>
              <HeaderBack />
              <DiivixApp />
            </>
          }
        />
        <Route
          path="/tmovies"
          element={
            <>
              <HeaderBack />
              <MovieReactApp />
            </>
          }
        />
        <Route
          path="/weather"
          element={
            <>
              <HeaderBack />
              <WeatherReactApp />
            </>
          }
        />
        <Route
          path="/quantumn"
          element={
            <>
              <HeaderBack />
              <QuantumTechWebsite />
            </>
          }
        />
        <Route
          path="/fleet"
          element={
            <>
              <HeaderBack />
              <FleetManagement />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <HeaderBack />
              <div>Page Not Found</div>
            </>
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
