import React from "react";
import ReusableComponent from "./ReusableComponent";
import docImage1 from "./assets/doc1.png";
import docImage3 from "./assets/doctor2.png";
import docImage2 from "./assets/doc2.png";


const DoctorAppointmentPage = () => {
  const data = {
    title: "Doctor Appointment App",
    sliderImages: [docImage1, docImage2,docImage3],
    overview:
      "A mobile application designed for scheduling appointments with doctors.An Online doctor consultation platform enabling users to book appointments, chat, and make voice or video calls with doctors..",
    designPrinciples: [
      {
        title: "Color Palette",
        description: "Background: White (#FFFFFF) ,Secondary Background: #6B46C1 (Purple),Button: #6B46C1 (Purple),Text Colors: Black (#000000) and Light Gray (#A0AEC0)",
      },
      {
        title: "Typography",
        description: "Inter (modern and readable) .Modern fonts that are easy to read. ",
      },
      {
        title: "Responsiveness",
        description: "Fully functional across devices.",
      },
    ],
    links: [
      {
        url: "https://figma.com/prototype/doc-app",
        label: "Figma Prototype",
        type: "button-outline",
      },
      {
        url: "https://github.com/your-repo/doc-app",
        label: "Github",
        type: "button-solid",
      },
    ],
  };

  return <ReusableComponent {...data} />;
};

export default DoctorAppointmentPage;
