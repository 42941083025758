import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/header_style.css";

// Header for Home Page
export const HeaderHome = () => {
  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">FRANKOE.</div>
        <div className="ellipse"></div>
        <div className="line animate-line"></div> 
      </div>
    </div>
  );
};

// Header for Other Pages
export const HeaderBack = () => {
  const navigate = useNavigate();
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    setCanGoBack(window.history.length > 2); // Check navigation history only once
  }, []);

  const handleBack = () => {
    if (canGoBack) {
      navigate(-1); // Go back
    } else {
      navigate("/"); // Go to home page
    }
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">FRANKOE.</div>
        <div className="back-arrow" onClick={handleBack}>
          ←
        </div>
        <div className="line animate-line"></div>
      </div>
    </div>
  );
};
export default HeaderBack;