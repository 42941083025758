import React from "react";
import "./styles/header_style.css";

const HeaderHome = () => {
  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">FRANKOE.</div>
        <div className="ellipse"></div>
        <div className="line animate-line"></div> 
      </div>
    </div>
  );
};

export default HeaderHome;

